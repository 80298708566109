import React from 'react'

import {WebPage, NotFound} from 'src/components/layout'
import {WebPageCommonProps} from 'src/components/layout/WebPage'

// TODO:
// Should we have Webpage wrapper for 404?
// Maybe we should just force user to homepage
export default function Custom404(): JSX.Element {
  const emptyCommon: WebPageCommonProps = {
    categories: [],
    cmsSubscription: {
      enabled: false,
      initialData: undefined,
    },
  }

  return (
    <WebPage
      common={emptyCommon}
      headTitle="404 - ELKO"
      backgroundColor="white"
      seoIndexing={false}
    >
      <NotFound />
    </WebPage>
  )
}
